<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="1100"
      :persistent="load"
    >
      <v-card
        flat
        :disabled="load"
      >
        <v-card-title>
          Liquidaciones Diarias asociadas a la Liq. Mensual {{ liquidacion_id }}
          <v-spacer></v-spacer>
          <v-btn icon @click="activo = false"><v-icon title="Cerrar">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12" class="py-1">
              <v-data-table
                class="cebra elevation-2 mt-2"
                :headers="headers"
                :items="liquidaciones"
                :loading="load"
                dense
                :search="search"
                sort-by="fecha_pago"
                :sort-desc="true"
              >
                <!-- Buscador en el datatable -->
                <template v-slot:top>
                  <v-row class="d-flex justify-end pa-2" no-gutters>
                    <v-col cols="6" sm="3">
                      <SearchDataTable
                        v-model="search"
                      />
                    </v-col>
                  </v-row>
                </template>
                <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
                <template
                  v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
                  v-slot:[`item.${header.value}`]="{ value }"
                >
                  {{ header.formatter(value) }}
                </template>
                <!-- Msj que se mostrara si no existen resultados -->
                <template v-slot:no-data>
                  <v-alert
                    class="mx-auto mt-4"
                    max-width="400"
                    type="warning"
                    border="left"
                    dense
                    text
                  >
                    Sin Liquidaciones para mostrar.
                  </v-alert>
                </template>
                <!-- Fecha Pago -->
                <template v-slot:[`item.fecha_pago`]="{ item }">
                  {{ format_date(item.fecha_pago) }}
                </template>
                <!-- Fecha Emision -->
                <template v-slot:[`item.fecha_emision`]="{ item }">
                  {{ format_date(item.fecha_emision) }}
                </template>
                <!-- Acciones -->
                <template v-slot:[`item.acciones`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn small icon color="success" @click="verLiquidacion(item)" v-on="on">
                        <v-icon small>fas fa-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>Ver</span>
                  </v-tooltip>
                </template>
                <!-- ultima row para el total -->
                <template slot="body.append">
                  <tr class="font-weight-bold">
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th class="text-center">Totales</th>
                    <th class="text-right">{{ format_money(liquidaciones.reduce((sum, liq) => sum + roundNumber(liq.total_bruto, 2), 0)) }}</th>
                    <th class="text-right">{{ format_money(liquidaciones.reduce((sum, liq) => sum + roundNumber(liq.total_neto, 2), 0)) }}</th>
                    <th class="text-right">{{ format_money(liquidaciones.reduce((sum, liq) => sum + roundNumber(liq.descuentos, 2), 0)) }}</th>
                    <th></th>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SearchDataTable from '../util/SearchDataTable.vue'
import { format_date, format_money, roundNumber } from '../../util/utils';
export default {
  props: {
    datos: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  computed: {
    activo: {
      get(){
        return this.datos.activo
      },
      set(value){
        this.$emit('setearModalLiqAsoc', value)
      }
    },
    liquidacion_id: {
      get(){
        return this.datos.liquidacion_id
      }
    }
  },
  components: {
    SearchDataTable,
  },
  data() {
    return {
      roundNumber: roundNumber,
      format_date: format_date,
      format_money: format_money,
      headers: [
        { text: 'Id', align: 'center', value: 'id' },
        { text: 'F. Pago', align: 'center', value: 'fecha_pago' },
        { text: 'F. Emis.', align: 'center', value: 'fecha_emision' },
        { text: 'Tarj.', align: 'center', value: 'tarjeta_nombre' },
        { text: 'Nro. Res.', align: 'center', value: 'nro_resumen' },
        { text: 'Nro. Com.', align: 'right', value: 'nro_comercio' },
        { text: 'Total Bruto', align: 'right', value: 'total_bruto', formatter: format_money },
        { text: 'Total Neto', align: 'right', value: 'total_neto', formatter: format_money },
        { text: 'Descuentos', align: 'right', value: 'descuentos', formatter: format_money },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      liquidaciones: [],
      load: false,
      search: ''
    }
  },
  methods: {
    limpiarModal(){
      this.search = ''
      this.liquidaciones = []
    },
    async initModal(){
      this.load = true
      let initPeticion = await this.$store.dispatch('liqMensuales/getLiqAsociadas', {liquidacion_id: this.liquidacion_id})
      this.load = false
      if (initPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: initPeticion.msj,
          color: 'error',
        })
        return
      }
      this.liquidaciones = initPeticion.liquidaciones
    },
    verLiquidacion(item){
      const routeData = this.$router.resolve({path: `/conciliacion-manual/${item.id}`})
      window.open(routeData.href, '_blank')
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false) this.limpiarModal()
      else this.initModal()
    }
  },
}
</script>

<style>

</style>